import {logo, imageMobile, imageWeb, imageWeb2, imageWeb3} from './images';

function App() {
  return (
    <div className="theme-mono theme-purple-mono bg-surfacePrimary w-full h-[100dvh] flex flex-col justify-between relative">
        <div className="w-full px-6 md:px-[80px] xl:px-[120px] 2xl:px-[150px] mt-6 mb-10 flex justify-between items-center">
          <img src={logo} alt="Astrofy" className="h-8 w-auto" />
          <a href="https://start.trynewmoon.com/login" className="btn text-white rounded-full h-[44px] w-[87px] grid place-items-center hover:opacity-80">
            <div className="w-[83px] h-[40px] bg-surfacePrimary rounded-full grid place-items-center">
              <p className="text-title-3-semibold text-white">Sign In</p>
            </div>
          </a>
        </div>
      
      
        <div className="flex flex-col md:flex-row md:gap-10 md:flex-row-reverse">
          <img alt="A cosmic illustration with various celestial bodies like moons and stars, 
          predominantly in shades of purple and black" className="w-full sm:hidden" src={imageMobile} />
          <img alt="A cosmic illustration with various celestial bodies like moons and stars, 
          predominantly in shades of purple and black" className="w-full hidden sm:flex md:hidden" src={imageWeb} />
          <img alt="A cosmic illustration with various celestial bodies like moons and stars, 
          predominantly in shades of purple and black" className="w-[55%] aspect-square hidden md:flex xl:hidden" src={imageWeb2} />
          <img alt="A cosmic illustration with various celestial bodies like moons and stars, 
          predominantly in shades of purple and black" className="w-[45%] max-h-[90%] aspect-square hidden xl:flex" src={imageWeb3} />
        
        <div className="w-full text-center md:text-left mt-8 flex flex-col items-center md:items-start md:pl-[80px] xl:pl-[120px] 2xl:pl-[150px] justify-center">
          <h1 className="serif text-[38px] leading-10 max-w-[310px] md:text-[64px] md:leading-[60px] md:max-w-[460px] text-symbolPrimary ">
            Astrological guide for personal transformation
          </h1>
          <p className="text-title-3-regular md:text-title-2-regular text-symbolSecondary mt-4 mb-8 max-w-[310px] md:max-w-[460px]">
            Discover how astrology can become a practical tool for your personal growth.
          </p>
            <a href="https://program.trynewmoon.com/relationships/" className="px-10 py-4 btn btn-shadow text-white 
            w-[153px] min-h-14 py-3 rounded-full text-title-3-semibold hover:opacity-80">
              Continue
            </a>
          <div className="text-text-medium text-symbolSecondary mt-8">
            <p>
              Already have an account?&nbsp;
              <a className="text-accent text-text-medium hover:opacity-80" href="https://start.trynewmoon.com/login">
                Sign In
              </a>
            </p>
          </div>
        </div>
        </div>
      <footer className="2xl:absolute 2xl:bottom-0 2xl: w-full px-6 px-6 md:px-[80px] xl:px-[120px] 2xl:px-[150px] py-8 flex flex-col gap-2 md:flex-row md:justify-between items-center text-symbolSecondary text-caption-1-regular">
        <div className="flex">
          <a className="text-caption-1-regular text-accent hover:opacity-80" href="https://billing.trynewmoon.com/static/terms">
            Terms and Conditions
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a className="text-caption-1-regular text-accent hover:opacity-80" href="https://billing.trynewmoon.com/static/privacy">
            Privacy Policy
          </a>
        </div>

        <p className="text-caption-1-regular text-white">
          Copyright © {new Date().getFullYear()} Newmoon
        </p>
      </footer>
    </div>
  );
}

export default App;
